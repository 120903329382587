/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { Dashboard } from '../../../components/microfrontends/Dashboard';
import { Payment } from '../../../components/microfrontends/Payment';
import { AccountManagement } from '../../../components/microfrontends/AccountManagement';
import { Inventory } from '../../../components/microfrontends/Inventory';
import { Reports } from '../../../components/microfrontends/Reports';
// import { Configurations } from '../../../components/microfrontends/Configurations';
import { Customer } from '../../../components/microfrontends/Customer';
import { DOF } from '../../../components/microfrontends/DOF';
import { Reporting } from '../../../components/microfrontends/Reporting';
import { Agreement } from '../../../components/microfrontends/Agreement';
import { Payment1 } from '../../../components/microfrontends/payment1';
import { POReceiving } from '../../../components/microfrontends/POReceiving';
import { MPO } from '../../../components/microfrontends/MPO';
import { AcimaPo } from '../../../components/microfrontends/AcimaPo';
import { CashManagement } from '../../../components/microfrontends/CashManagement';
import { DailyActivityPlanner } from '../../../components/microfrontends/DailyActivityPlanner';
import { ManualPO } from '../../../components/microfrontends/ManualPO';
import { Pricing } from '../../../components/microfrontends/Pricing';
import { OperationsManagement } from './../../../components/microfrontends/OperationsManagement';
import { TaxManagement } from '../../../components/microfrontends/TaxExempt';
import { Finance } from '../../../components/microfrontends/Finance';
import { APP_COMPONENT_WITHOUT_SUBMENU } from '../../../constants/constants';

const getAppSubComponentDetails = (components: any, response?: any) => {
  const res: any = {
    appName: components.appName,
    appComponentName: components.appComponentName,
    appSubComponenet: components.appSubComponentName,
    path: '',
    routePath: '',
    component: 'REACT COMPONENET',
    displayOrder: 0,
  };
  console.log(components, 'getAppSubComponentDetails')
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (components.appSubComponentName.replaceAll(' ', '').toUpperCase()) {
    // case 'DASHBOARD': {
    //   res.path = `/dashboard/homepage`;
    //   res.component = Dashboard;
    //   break;
    // }
    case 'TAKEPAYMENT': {
      res.path = `/payment1/paymentsearch`;
      res.routePath = `/payment1`;
      res.component = Payment1;
      res.displayOrder = 2;
      break;
    }
    case 'RACPAY': {
      res.path = `/payment`;
      res.routePath = `/payment`;
      res.component = Payment;
      res.displayOrder = 3;
      break;
    }
    case 'CREATECUSTOMER': {
      res.path = `/customer/createcustomer`;
      res.routePath = `/customer`;
      res.component = Customer;
      res.displayOrder = 4;
      break;
    }
    case 'CREATEAGREEMENT': {
      res.path = `/customer/searchcustomer`;
      res.routePath = `/customer`;
      res.component = Customer;
      res.displayOrder = 4;
      break;
    }
    case 'ACTIVEMAILINGLABELS': {
      res.path = `/dashboard/homepage/activemailing`;
      res.routePath = `/dashboard`;
      res.component = Dashboard;
      res.displayOrder = 5;
      break;
    }
    case 'AGREEMENTTRANSFERRECEIVE': {
      res.path = `/agreement/transferreceive`;
      res.routePath = `/agreement`;
      res.component = Agreement;
      res.displayOrder = 6;
      break;
    }
    case 'PENDINGAGREEMENT': {
      res.path = `/agreement/pendingagreement`;
      res.routePath = `/agreement`;
      res.component = Agreement;
      res.displayOrder = 7;
      break;
    }
    case 'PASTDUELIST': {
      res.path = `/am/past-due-list`;
      res.routePath = `/am`;
      res.component = AccountManagement;
      res.displayOrder = 7;
      break;
    }
    case 'AMACTIVITYLOG': {
      res.path = `/am/activity-log`;
      res.routePath = `/am`;
      res.component = AccountManagement;
      res.displayOrder = 8;
      break;
    }
    case 'SEARCHINVENTORY': {
      res.path = `/inventory/inventorysearch`;
      res.routePath = `/inventory`;
      res.component = Inventory;
      res.displayOrder = 9;
      break;
    }
    case 'IDLEINVENTORYAUDIT': {
      res.path = `/inventory/audit/history`;
      res.routePath = `/inventory`;
      res.component = Inventory;
      res.displayOrder = 10;
      break;
    }
    case 'PRINTEDITPRICETAGS': {
      res.path = `/inventory/printeditpricetag`;
      res.routePath = `/inventory`;
      res.component = Inventory;
      res.displayOrder = 10;
      break;
    }
    case 'NEWPACKAGEITEMSEARCH': {
      res.path = `/inventory/packagepricetag`;
      res.routePath = `/inventory`;
      res.component = Inventory;
      res.displayOrder = 11;
      break;
    }
    case 'INVENTORYTRANSFERRECEIVE': {
      res.path = `/inventory/inventorytransfer`;
      res.routePath = `/inventory`;
      res.component = Inventory;
      res.displayOrder = 12;
      break;
    }
    case 'ITEMSBEINGSERVICED': {
      res.path = `/inventory/itembeingserviced`;
      res.routePath = `/inventory`;
      res.component = Inventory;
      res.displayOrder = 13;
      break;
    }
    case 'MANUALPO-OLD': {
      res.appSubComponenet = 'Manual PO';
      res.path = `/manual-po`;
      res.routePath = `/manual-po`;
      res.component = ManualPO;
      res.displayOrder = 14;
      break;
    }
    case 'MANUALPO': {
      res.path = `/mpo`;
      res.routePath = `/mpo`;
      res.component = MPO;
      res.displayOrder = 15;
      break;
    }
    case 'PORECEIVING': {
      res.path = `/receivingpo/purchaseorders`;
      res.routePath = `/receivingpo`;
      res.component = POReceiving;
      res.displayOrder = 16;
      break;
    }
    case 'POREVERSE': {
      res.path = `/receivingpo/purchaseorders?rev`;
      res.routePath = `/receivingpo`;
      res.component = POReceiving;
      res.displayOrder = 17;
      break;
    }
    case 'ACIMAPORECEIVING': {
      res.path = `/acimapo/poreceive`;
      res.routePath = `/acimapo`;
      res.component = AcimaPo;
      res.displayOrder = 18;
      break;
    }
    case 'REPORTS': {
      res.path = `/reports`;
      res.routePath = `/reports`;
      res.component = Reports;
      res.displayOrder = 19;
      break;
    }
    case 'REPORTING': {
      res.appName = 'Reports';
      res.path = `/reporting`;
      res.routePath = `/reporting`;
      res.component = Reporting;
      res.displayOrder = 20;
      break;
    }
    case 'DAILYACTIVITYPLANNER': {
      res.path = `/dap`;
      res.routePath = `/dap`;
      res.component = DailyActivityPlanner;
      res.displayOrder = 21;
      break;
    }
    case 'SENDDIGITALORDERFORM': {
      res.path = `/storemgmt/digitalorderform`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 22;
      break;
    }
    case 'ROUTEASSIGNMENT': {
      res.path = `/storemgmt/routeassignment`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 23;
      break;
    }
    case 'LOOKUPSTORE': {
      res.path = `/storemgmt/lookupstore`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 24;
      break;
    }
    case 'PINMANAGEMENT': {
      res.path = `/storemgmt/pinmanagement`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 25;
      break;
    }
    case 'CLOSEENDOFDAY': {
      res.path = `/storemgmt/closeeod`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 26;
      break;
    }
    case 'DAPEVENTS': {
      // Checking the Daily Activity Planner present in the respone
      const legacyDapPresent = response.some((obj: any) => obj.appSubComponentName == 'Daily Activity Planner');
      res.appSubComponenet = legacyDapPresent ? 'DAP Events' : 'Daily Activity Planner';
      res.path = `/storemgmt/dap`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = legacyDapPresent ? 27 : 21;
      break;
    }
    case 'DROPS': {
      res.path = `/cashmgmt/drop`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 28;
      break;
    }
    case 'FINALDROP': {
      res.path = `/cashmgmt/final/drop`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 29;
      break;
    }
    case 'DROPRECONCILIATION': {
      res.path = `/cashmgmt/reconcil`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 30;
      break;
    }
    case 'DRAWERAUDIT': {
      res.path = `/cashmgmt/audit`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 31;
      break;
    }
    case 'CASHPAIDOUTOFDRAWER': {
      res.path = `/cashmgmt/paidout`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 32;
      break;
    }
    case 'PAIDINTODRAWER': {
      res.path = `/cashmgmt/paidin`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 33;
      break;
    }
    case 'CASHDROPLOG': {
      res.path = `/cashmgmt/cashdrop`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 34;
      break;
    }
    case 'PASTDROPRECEIPT': {
      res.path = `/cashmgmt/pastdrop`;
      res.routePath = `/cashmgmt`;
      res.component = CashManagement;
      res.displayOrder = 35;
      break;
    }
    case 'FIELDSUPPORTCENTER': {
      res.path = `/storemgmt/fsc/details`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 36;
      break;
    }
    case 'CONTEST': {
      res.path = `/storemgmt/contest/details`;
      res.routePath = `/storemgmt`;
      res.component = DOF;
      res.displayOrder = 37;
      break;
    }
    case 'ZONE': {
      res.path = `/pricing/zone`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 38;
      break;
    }
    case 'STOREZONE': {
      res.path = `/pricing/storezone`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 39;
      break;
    }
    case 'PRICING': {
      res.path = `/pricing/manage`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 40;
      break;
    }
    case 'AUTOTERM': {
      res.path = `/pricing/autoterm`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 41;
      break;
    }
    case 'PACKAGE': {
      res.path = `/pricing/package`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 42;
      break;
    }
    case 'PRICINGQUEUE': {
      res.path = `/pricing/queue`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 43;
      break;
    }
    case 'PROMOSCHEDULER': {
      res.path = `/pricing/promoscheduler`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 44;
      break;
    }
    case 'PRICINGUPLOADS': {
      res.path = `/pricing/upload`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 45;
      break;
    }
    case 'PRICINGREPORTS': {
      res.appSubComponenet = 'Reports';
      res.path = `/pricing/report`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 46;
      break;
    }
    case 'CUSTOMER': {
      res.appSubComponenet = 'Customer';
      res.path = `/operations/customer`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 47;
      break;
    }
    case 'AGREEMENT': {
      res.appSubComponenet = 'Agreement';
      res.path = `/operations/agreement`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 48;
      break;
    }
    case 'LOCATION': {
      res.appSubComponenet = 'Location';
      res.path = `/operations/location`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 49;
      break;
    }
    case 'INVENTORY': {
      res.appSubComponenet = 'Inventory';
      res.path = `/operations/inventory`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 50;
      break;
    }
    case 'TAXEXEMPTION': {
      res.appSubComponenet = 'Tax Exemption';
      res.path = `/taxmgmt`;
      res.routePath = `/taxmgmt`;
      res.component = TaxManagement;
      res.displayOrder = 51;
      break;
    }
    case 'RETURNCHECK': {
      res.appSubComponenet = 'Returned Check';
      res.path = `/finance/returncheck`;
      res.routePath = `/finance`;
      res.component = Finance;
      res.displayOrder = 52;
      break;
    }
    case 'CREDITCARDCHARGEBACK': {
      res.appSubComponenet = 'Credit Card Chargeback';
      res.path = `/finance/creditcardcc`;
      res.routePath = `/finance`;
      res.component = Finance;
      res.displayOrder = 53;
      break;
    }
    case 'LEGALHOLD': {
      res.appSubComponenet = 'Legal';
      res.path = `/operations/legal`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 54;
      break;
    }
    case 'MENUPERMISSIONS': {
      res.appSubComponenet = 'Menu Permissions';
      res.path = `/operations/admin/menupermissions`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 55;
      break;
    }
    case 'APPLICATIONCONFIGURATIONS': {
      res.appSubComponenet = 'Application Configurations';
      res.path = `/operations/admin/appconfigurations`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 56;
      break;
    }
    case 'RESETPIN': {
      res.appSubComponenet = 'Reset PIN';
      res.path = `/operations/admin/resetpin`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 56;
      break;
    }
    case 'B2BLEASINGPAYMENTS': {
      res.path = `/operations/admin/alemanagement`;
      res.routePath = `/operations`;
      res.component = OperationsManagement;
      res.displayOrder = 57;
      break;
    }  
    case 'SECONDARYCRITERIA': {
      res.path = `/pricing/secondarycriteria`;
      res.routePath = `/pricing`;
      res.component = Pricing;
      res.displayOrder = 58;
      break;
    }
    default: {
      res.path = `/dashboard/homepage`;
      res.routePath = `/dashboard`;
      res.component = Dashboard;
      res.displayOrder = 1;
      break;
    }
  }
  return res;
};

const makeResponseStruct = (
  appDetails: any,
  allowedRoutePaths: any,
  currentRes = 0,
  response?: any
): any => {
  console.log(appDetails, 'appDetails')
  console.log(allowedRoutePaths, 'allowedRoutePaths')
  console.log(response, 'response')
  console.log(appDetails[0], 'appDetails[0]')
  console.log(currentRes, 'currentRes')

  if (appDetails?.length == 0) {
    console.log(currentRes, 'Final currentRes')
    console.log(allowedRoutePaths, 'Final allowedRoutePaths')
    return { currentRes, allowedRoutePaths };
  }

  //appComponentId -
  let res: any = currentRes || {
    app: appDetails[0].appName,
    appComponents: [],
    displayOrder: appDetails[0].appDisplaySequence,
  };
  const isComponentPresent = res.appComponents.some(
    (el: any) => el.appComponent == appDetails[0].appComponentName
  );
  if (
    !isComponentPresent &&
    !APP_COMPONENT_WITHOUT_SUBMENU.includes((appDetails[0].appComponentName).toUpperCase())
  ) {
    res.appComponents.push({
      appComponent: appDetails[0].appComponentName,
      appSubComponents: [],
      displayOrder: appDetails[0].appComponentDisplaySequence,
    });
  }
  //app component sort
  res.appComponents = res.appComponents.sort(
    (prev: any, next: any) => prev.displayOrder - next.displayOrder
  );
  // eslint-disable-next-line no-console
  console.log(`res.appComponents`, res.appComponents);
  const subComponentDetail = getAppSubComponentDetails(appDetails[0], response);
  console.log(subComponentDetail, 'subComponentDetail')
  // This is for react router
  if (
    !allowedRoutePaths.find(
      (item: any) => item.routePath == subComponentDetail.routePath
    )
  ) {
    allowedRoutePaths.push(subComponentDetail);
  }
  if (appDetails[0].appComponentName == appDetails[0].appSubComponentName) {
    res = { ...res, ...subComponentDetail };
  }

  res.appComponents.forEach((el: any) => {
    if (
      el.appComponent ==
      (subComponentDetail.appComponentName || subComponentDetail.appName)
    ) {
      el.appSubComponents.push(subComponentDetail);
    }
  });

  appDetails.splice(0, 1);
  console.log('spliced appDetails', appDetails)
  return makeResponseStruct(appDetails, allowedRoutePaths, res, response);
};

const groupByAppId = (response: any) => {
  let responseStruct = new Map();
  response.forEach((el: any) => {
    if (!responseStruct.has(String(el.appDisplaySequence))) {
      responseStruct.set(String(el.appDisplaySequence), [el]);
    } else {
      const appResponse = responseStruct.get(String(el.appDisplaySequence));
      appResponse.push(el);
      responseStruct.set(String(el.appDisplaySequence), appResponse);
    }
  });
  responseStruct = new Map([...responseStruct].sort());
  return responseStruct;
};

export const getConfiguredRoutes = (res: any) => {
  console.log('getConfiguredRoutesResponse', res)
  const response = groupByAppId(res);
  // eslint-disable-next-line no-console
  console.log(`groupByAppIdresponse`, response);
  let allowedRoutes: any[] = [];

  const finalRespone: any = [];
  response.forEach((el) => {
    const { currentRes, allowedRoutePaths } = makeResponseStruct(
      el,
      allowedRoutes,
      0,
      res
    );

    if (currentRes.appComponents && currentRes.appComponents.length > 0) {
      currentRes.appComponents.forEach((appComponentDetail: any) => {
        //looping appComponet's subComponent array
        if (
          appComponentDetail.appSubComponents &&
          appComponentDetail.appSubComponents.length > 0
        ) {
          //to filter only the unique subcomponents from the appComponentDetail.appSubComponents
          const uniqueAppSubComponents: any[] = [];
          for (let index = 0; index < appComponentDetail.appSubComponents.length; index++) {
            const subcomponet = appComponentDetail.appSubComponents[index];
            const exist = uniqueAppSubComponents.find((e: any) => e.appSubComponenet === subcomponet.appSubComponenet);
            if (!exist) {
              uniqueAppSubComponents.push(subcomponet);
            }
          }
          console.log(uniqueAppSubComponents, appComponentDetail.appSubComponents)
          //After sorting appSubComponents(i.e)uniqueAppSubComponents, assigning to the same property
          appComponentDetail.appSubComponents =
            uniqueAppSubComponents.sort(
              (prev: any, next: any) => prev.displayOrder - next.displayOrder
            );
        }
      });
    }
    allowedRoutes = allowedRoutePaths;
    finalRespone.push(currentRes);
  });
  allowedRoutes.sort(
    (prev: any, next: any) => prev.displayOrder - next.displayOrder
  );
  // eslint-disable-next-line no-console
  console.log('finalRespone', finalRespone);
  return { finalRespone, allowedRoutes };
};
